import { Spinner } from 'react-bootstrap';
import './section-loader.style.scss'

const SectionLoader = ({ text = 'Please wait...' }) => {
    return (
        <div className="section-loader-container">
            <Spinner animation="border" role="status"/>
            <div>
                <p>{text}</p>
            </div>
        </div>
    )
}

export default SectionLoader;
