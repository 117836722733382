import React, { useContext } from 'react'
import ReportFilter from '../../components/filter/report-filter/report-filter.component'
import { SideMenuContext } from '../../providers/SideMenuProvider/SideMenuContext'
import { Container } from 'react-bootstrap'

const Report = () => {
    const { expanded } = useContext(SideMenuContext)

    const withSideBar = {
        marginLeft: expanded ? "224px" : "48px",
    };

    return (
        <Container style={withSideBar} fluid>
            <ReportFilter />
        </Container>
    )
}

export default Report