import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Row, Spinner } from "react-bootstrap";
import { getObjOptions, sortAlphabetically } from "../../../helpers/generic-functions";
import { getAllUsers } from "../../../services/employee.service";
import { getAllProjects } from "../../../services/project.service";
import PrimaryDropdown from "../../dropdown/primary-dropdown/primary-dropdown.component";
import { getProjectTimeLog } from "../../../services/project-time-log.service";
import {
  dateFormat,
  dateIsAfter,
  dateIsBefore,
  getDate,
  toDate
} from "../../../helpers/date-time-management";
import PrimaryDatePicker from "../../date-picker/primary-date-picker/primary-date-picker";
import { getAllTasks } from "../../../services/task.service";
import "./dev-report-filter.style.scss";
import { getEmployeeTimeLog } from "../../../services/time-tracker.service";
import PrimaryCheckbox from '../../inputs/primary-checkbox/primary-checkbox'
import PrimaryRadio from "../../inputs/primary-radio/primary-radio";
import PrimaryForm from "../../forms/primary-form";
import { getAllAgileProjects } from "../../../services/agile-project.service";
import { getAgileProjectTimeLog } from "../../../services/agile-project-time-tracker.service";
import { getAllIssues } from "../../../services/issue.service";
import { exportToCSV } from "../../../helpers/file";
import { generateReport } from "../../../helpers/report";
import { getMergedEmployeeOptions } from "../../../helpers/user-management";
import { getProjectOptions } from "../../../helpers/project-management";
import { getAllAccounts } from "../../../services/account.service";

const DevReportFilter = () => {
  const [employees, setEmployees] = useState({});
  const [customers, setCustomers] = useState({});
  const [projects, setProjects] = useState({});
  const [filter, setFilter] = useState({
    employees: [],
    projects: [],
    tasks: [],
    from_date: "",
    to_date: "",
    is_archive: ['0'],
    timesheet_module: 'agile'
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProjectOptions, setIsLoadingProjectOptions] = useState(false);
  const [isLoadingEmployeeOptions, setIsLoadingEmployeeOptions] =
    useState(false);
  const [error, setError] = useState("");
  const [warning, setWarning] = useState("");

  const exportReport = (timelog, tasks) => {
    const report = generateReport(timelog, tasks, filter, employees, projects, customers);   
    if (report?.length) {
      exportToCSV(report, getDate());
    } else {
      setWarning("There is no records yet");
    }
  };

  const getProjects = () => {
    setIsLoadingProjectOptions(true);
    getAllProjects(filter).then((projects) => {
      if (projects && projects?.length) {
        setFilter({
          ...filter,
          projects: []
        });
        setProjects(getProjectOptions(projects));
      }
      setIsLoadingProjectOptions(false);
    });
  };

  const getAgileProjects = () => {
    setIsLoadingProjectOptions(true);
    getAllAgileProjects(filter).then((projects) => {
      if (projects && projects?.length) {
        setFilter({
          ...filter,
          projects: []
        });
        setProjects(getObjOptions(projects));
      }
      setIsLoadingProjectOptions(false);
    });
  };

  const getEmployeeProjects = async () => {
    setIsLoadingProjectOptions(true);
    const timeLogs = await getEmployeesTimeLog();
    if (timeLogs) {

      const tasks = await getTimeLogTasks(timeLogs);
      if (tasks && tasks?.length) {
        var employeeProjects = [];
        tasks.forEach((task) => {
          const project = projects.find(
            (project) => project.value === task.projectId
          );
          if (project && !employeeProjects.includes(project)) {
            employeeProjects.push(project);
          }
        });
        setFilter({ ...filter, projects: employeeProjects });
      } else {
        setWarning("There are no projects for user in this range");
      }
    } else {
      setWarning("There is no records in this filter area");
    }

    setIsLoadingProjectOptions(false);
  };

  const getProjectEmployees = () => {
    setIsLoadingEmployeeOptions(true);
    getTasks().then((tasks) => {
      if (tasks && tasks?.length) {
        var projectEmployees = [];
        tasks.forEach((task) => {
          const employee = employees.find(
            (employee) => employee.value === task.responsible_id
          );
          if (employee && !projectEmployees.includes(employee)) {
            projectEmployees.push(employee);
          }
        });
        setFilter({ ...filter, employees: projectEmployees });
      }
      setIsLoadingEmployeeOptions(false);
    });
  };

  const getEmployees = () => {
    setIsLoadingEmployeeOptions(true);
    getAllUsers().then((users) => {
      if (users && users?.length) {
        setEmployees(getMergedEmployeeOptions(users));
      }
      setIsLoadingEmployeeOptions(false);
    });
  };

  const getCustomers = () => {
    getAllAccounts().then((accounts) => {
      if (accounts && accounts?.length) {
        setCustomers(getObjOptions(accounts));
      }
    });
  };

  const getTasks = () => {
    return getAllTasks(filter).then((tasks) => {
      if (tasks && tasks?.length) {
        return tasks;
      } else {
        return [];
      }
    });
  };

  const getTimeLogTasks = async (timelogs) => {
    var taskIds = [];
    // I'm filtering here because the timelog filter is not working from the API end
    timelogs.forEach((timelog) => {
      if (!taskIds.includes(timelog.timesheet_model_id)) {
        if (timelog.timesheet_module === filter.timesheet_module) {
          taskIds.push(timelog.timesheet_model_id);
        }
      }
    });
    if (taskIds.length !== 0) {
      setIsLoading(true);
      if (filter.timesheet_module === 'agile') {
        const result = await getAllIssues({ tasks: taskIds })
        setIsLoading(false);
        if (result && result?.length) {
          const tasks = result.map((task) => {
            return {
              label: task.name,
              value: task.id,
              projectId: task.project_id,
            };
          });
          return tasks
        }
      }
      else {
        const result = await getAllTasks({ tasks: taskIds })
        setIsLoading(false);
        if (result && result?.length) {
          const tasks = result.map((task) => {
            return {
              label: task.name,
              value: task.id,
              projectId: task.model_id,
            };
          });
          return tasks
        }
      }

    } else {
      return
    }
  };

  const getProjectsTimeLog = () => {
    setIsLoading(true);
    var timelogRequests = [];
    filter.projects?.forEach((project) => {
      timelogRequests.push(filter.timesheet_module === 'agile' ? getAgileProjectTimeLog(filter, project.value) : getProjectTimeLog(filter, project.value));
    });
    return Promise.all(timelogRequests).then((res) => {
      var timeLogs = [];
      Object.keys(res).map((index) => {
        if (res[index] && res[index]?.length) {
          const project = filter.projects[index];
          res[index].map((task) => {
            task.project = project;
            return timeLogs.push(task);
          });
        }
      });
      setIsLoading(false);
      if (timeLogs?.length) {
        return timeLogs
      }
    });
  };

  const getEmployeesTimeLog = () => {
    setIsLoading(true);
    return getEmployeeTimeLog(filter).then((timeLogs) => {
      setIsLoading(false);
      if (timeLogs?.length) {
        return timeLogs
      }
    });
  };

  useEffect(() => {
    getEmployees();
    getCustomers();
  }, []);

  useEffect(() => {
    if (filter.is_archive.length !== 0 || filter.timesheet_module) {
      filter.timesheet_module === 'agile' ? getAgileProjects() : getProjects();
    }
  }, [filter.is_archive, filter.timesheet_module]);

  useEffect(() => {

    if (filter.from_date && filter.to_date) {
      filter.timesheet_module === 'agile' ? getAgileProjects() : getProjects();
    }
  }, [filter.from_date, filter.to_date]);


  const handleExport = async () => {
    var timeLogs = []
    var tasks = []
    if (filter.is_archive.length === 0) {
      setWarning("Please fill at least one project type");
    } else if (
      (filter?.projects?.length && filter?.employees?.length) ||
      (filter?.projects?.length && !filter?.employees?.length)
    ) {

      timeLogs = await getProjectsTimeLog();
      if (tasks && timeLogs) {
        tasks = await getTimeLogTasks(timeLogs);
        if (tasks) exportReport(timeLogs, tasks);
        else setWarning("There is no records in this filter area")
      } else {
        setWarning("There is no records in this filter area");
      }
    } else if (!filter?.projects?.length && filter?.employees?.length) {
      timeLogs = await getEmployeesTimeLog();
      if (timeLogs) {
        tasks = await getTimeLogTasks(timeLogs);
        if (tasks) exportReport(timeLogs, tasks);
        else setWarning("There is no records in this filter area")
      } else {
        setWarning("There is no records in this filter area");
      }
    } else if (!filter?.projects?.length && !filter?.employees?.length) {
      setWarning("Please fill at least one project or employee");
    } else {
      setError("Error");
    }
  };

  const handleFromDate = (date) => {
    if (filter?.to_date) {
      if (dateIsAfter(dateFormat(date, "YYYY-MM-DD"), filter.to_date)) {
        setWarning("[From date] is larger than [to date]");
      } else {
        setFilter({
          ...filter,
          from_date: dateFormat(date, "YYYY-MM-DD"),
        });
      }
    } else {
      setFilter({
        ...filter,
        from_date: dateFormat(date, "YYYY-MM-DD"),
      });
    }
  };

  const handleToDate = (date) => {
    if (filter?.from_date) {
      if (dateIsBefore(dateFormat(date, "YYYY-MM-DD"), filter.from_date)) {
        setWarning("[to date] is smaller than [from date]");
      } else {
        setFilter({
          ...filter,
          to_date: dateFormat(date, "YYYY-MM-DD"),
        });
      }
    } else {
      setFilter({
        ...filter,
        to_date: dateFormat(date, "YYYY-MM-DD"),
      });
    }
  };

  const clearFilter = () => {
    setFilter({
      employees: [],
      projects: [],
      tasks: [],
      is_archive: [],
      from_date: "",
      to_date: "",
    });
  };

  const dismissError = () => {
    setError("");
  };

  const dismissWarning = () => {
    setWarning("");
  };

  const handleProjectTypeChange = (event) => {
    // Destructuring
    const { value, checked } = event.target;

    // Case 1 : The user checks the box
    if (checked) {
      setFilter({
        ...filter,
        is_archive: [...filter.is_archive, value]
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setFilter({
        ...filter,
        is_archive: filter.is_archive.filter((event) => event !== value)
      })
    }
  };

  return (
    <div
      className="report-filter"
    >
      {error && (
        <Alert
          variant="danger"
          className="top-right"
          onClose={() => dismissError()}
          dismissible
        >
          Error: {error}
        </Alert>
      )}
      {warning && (
        <Alert
          variant="warning"
          className="top-right"
          onClose={() => dismissWarning()}
          dismissible
        >
          Warning: {warning}
        </Alert>
      )}
      <Row className="page-header py-3">
        <Col sm={12}>
          <div>Dev Reports</div>
        </Col>
      </Row>
      <Row className="page-sub-header py-4">
        <Col sm={12}>
          <div>Please add your task completion dates below</div>
        </Col>
      </Row>
      <Row className="">
        <Col sm={4}>
          <PrimaryDatePicker
            placeholder="From Date"
            selectedDate={filter?.from_date ? toDate(filter.from_date) : ""}
            onChange={handleFromDate}
          />
        </Col>
        <Col sm={4}>
          <PrimaryDatePicker
            placeholder="To Date"
            selectedDate={filter?.to_date ? toDate(filter.to_date) : ""}
            onChange={handleToDate}
          />
        </Col>
      </Row>
      <Row className="page-sub-header py-4">
        <Col sm={12}>
          <div>Please select at least one project or employee below</div>
        </Col>
      </Row>
      <Row className="">
        <Col sm={4}>
          <PrimaryDropdown
            name="projects"
            placeholder="Select Project"
            selectedOption={filter?.projects ?? []}
            options={
              projects?.length ? sortAlphabetically(projects, "label") : []
            }
            onChange={(options) => setFilter({ ...filter, projects: options })}
            isLoading={isLoadingProjectOptions}
            isDisabled={isLoadingProjectOptions}
            multiple
          />
        </Col>
        <Col sm={4}>
          <PrimaryDropdown
            name="employees"
            placeholder="Select Employee"
            selectedOption={filter?.employees ?? []}
            options={
              employees?.length ? sortAlphabetically(employees, "label") : []
            }
            onChange={(options) => {
              setFilter({ ...filter, employees: options })
            }}
            isLoading={isLoadingEmployeeOptions}
            isDisabled={isLoadingEmployeeOptions}
            multiple
          />
        </Col>
      </Row>
      <Row className="">
        <Col sm={4}>
          <Button
            variant="link"
            onClick={() => getProjectEmployees()}
            disabled={
              filter?.projects?.length && !filter.employees?.length
                ? false
                : true
            }
          >
            <i className="fa fa-plus pr-2" aria-hidden="true" />
            Auto Fill Employees
          </Button>
        </Col>
        <Col sm={4}>
          <Button
            variant="link"
            onClick={() => getEmployeeProjects()}
            disabled={
              filter?.employees?.length && !filter?.projects?.length
                ? false
                : true
            }
          >
            <i className="fa fa-plus pr-2" aria-hidden="true" />
            Auto Fill Projects
          </Button>
        </Col>
      </Row>

      <Row className="page-sub-header py-4">
        <Col sm={12}>
          <div>Please select at least one Project type</div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={4}>

          <PrimaryCheckbox
            type='checkbox'
            id='active-projects'
            label='Active Projects'
            value='0'
            defaultChecked={true}
            onChange={handleProjectTypeChange}
            isDisabled={isLoadingProjectOptions}
          />

          <PrimaryCheckbox
            type='checkbox'
            label='Archive Projects'
            id='archive-projects'
            value='1'
            onChange={handleProjectTypeChange}
            isDisabled={isLoadingProjectOptions}
          />
        </Col>
      </Row>

      <Row className="page-sub-header py-4">
        <Col sm={12}>
          <div>Please select one Project Category</div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={4}>
          <PrimaryForm>
            <PrimaryRadio
              id='regular-projects'
              label='Regular Projects'
              value='task'
              name='projectCategory'
              onChange={(event) => {
                setFilter({
                  ...filter,
                  timesheet_module: event.target.value
                });
              }}
              isDisabled={isLoadingProjectOptions}
            />
            <PrimaryRadio
              label='Agile Projects'
              id='agile-projects'
              value='agile'
              defaultChecked={true}
              name='projectCategory'
              onChange={(event) => {
                setFilter({
                  ...filter,
                  timesheet_module: event.target.value
                });
              }}
              isDisabled={isLoadingProjectOptions}
            />
          </PrimaryForm>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col sm={2} className="d-flex align-items-center">
          <Button variant="outline-danger" onClick={() => clearFilter()}>
            <i className="fa fa-times pr-2" aria-hidden="true" />
            Clear
          </Button>
        </Col>
        <Col sm="auto" className="d-flex align-items-center">
          <Button
            variant="outline-primary"
            onClick={() => handleExport()}
            disabled={isLoading}
          >
            {isLoading || isLoadingProjectOptions ? (
              <Spinner
                as="span"
                className="mr-2"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <i className="fa fa-download pr-2" aria-hidden="true" />
            )}
            Export Dev Report
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default DevReportFilter;
