import React, { useContext } from 'react'
import FunctionCalendar from '../../components/calendar/release-V1.5.0/FunctionCalendar'
import { SideMenuContext } from '../../providers/SideMenuProvider/SideMenuContext';

const TaskSchedular = () => {
  const { expanded } = useContext(SideMenuContext)

  const withSideBar = {
    marginLeft: expanded ? "224px" : "48px",
  };
  
  return (
    <div style={withSideBar}>
      <FunctionCalendar />
    </div>
  )
}

export default TaskSchedular