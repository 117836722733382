import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'font-awesome/css/font-awesome.min.css';
import AuthProvider from './providers/AuthProvider/AuthProvider';
import SideMenuProvider from './providers/SideMenuProvider/SideMenuProvider';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <SideMenuProvider>
        <App />
      </SideMenuProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
