

import TimeGrid from '../TimeGrid'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import WeekView from './WeekView'


function workWeekRange(date, options) {

  return WeekView.range(date, options).filter(
    (d) => [6, 0].indexOf(d.getDay()) === -1
  )
}

export default function WorkWeek(Props) {
  let {
    date,
    localizer,
    min = localizer.startOf(new Date(), 'day'),
    max = localizer.endOf(new Date(), 'day'),
    scrollToTime = localizer.startOf(new Date(), 'day'),
    enableAutoScroll = true,
    ...props
  } = Props

  const currRange = useMemo(
    () => workWeekRange(date, Props),
    [date, localizer]
  )

  return (
    <>
      {currRange.length !== 0 &&
        currRange.map((item, index) => {
          let modified_range = [];
          modified_range.push(item);

          return <TimeGrid 
            key={index}
            localizer={localizer}
            eventOffset={10}
            date={date}
            max={max}
            min={min}
            range={modified_range}
            scrollToTime={scrollToTime}
            {...props}
            />
        }
        )}
    </>
  )
}

WorkWeek.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
}

WorkWeek.defaultProps = TimeGrid.defaultProps


WorkWeek.range = workWeekRange

WorkWeek.navigate = WeekView.navigate

WorkWeek.title = (date, { localizer }) => {
  let [start, ...rest] = workWeekRange(date, { localizer })

  return localizer.format({ start, end: rest.pop() }, 'dayRangeHeaderFormat')
}

