import { getAppSettings } from "../config/app-settings";
import axiosInstance from "../config/axios-config";

const limit = 100;

export const getAllAccounts = () => {
    var accounts = [];
    var result = 0;
    var page = 1;
    return appendNewAccountsFromServer(accounts, result, page)
  };

const getAccounts = (page) => {
    var params = {
      limit: limit,
      page: page,
      api_key: getAppSettings().apiKey,
    };

    return axiosInstance
      .get(
        `${getAppSettings().apiUrl}/crm/account/list`,{
          params: params
        }
      )
      .then((response) => {
        return response.data.response;
      })
      .catch((error) => {
        console.log(error)
      });
  };

const appendNewAccountsFromServer = (accounts, result, page) => {
    return getAccounts(page).then((response) => {
      if (response) {
        const data = response.items
        Object.keys(data).map((account) => {
          data[account].id = parseInt(data[account].id);
          return accounts.push(data[account])
        })

        result = result + response.count
        page = page + 1

        if (result !== response.total) {
          return appendNewAccountsFromServer(accounts, result, page);
        }
        return accounts;
      }
    });
  };