import Form from 'react-bootstrap/Form';

function PrimaryForm({children}) {
  return (
    <Form >
        {children}
    </Form>
  );
}

export default PrimaryForm;