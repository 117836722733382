import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { addDays, dateFormat, endOfWeekDate, getCurrentDate, inRangeComparisonIncludeEdges, startOfWeekDate, stringToDate } from '../../../helpers/date-time-management';
import Event from './Event';
import { useState, useMemo, useEffect } from 'react';
import WorkWeek from "../views/WorkWeek";
import { Alert } from 'react-bootstrap';
import PageLoader from '../../loaders/page-loader/page-loader.component';
import TaskSchedularFilter from '../../filter/task-schedular-filter/task-schedular-filter.component';
import { getAllUsers } from '../../../services/employee.service';
import { getAllProjects } from '../../../services/project.service';
import { getAllTasks } from '../../../services/task.service';

const localizer = momentLocalizer(moment)

const FunctionCalendar = () => {
  const [mainState, setMainState] = useState({
    // employee
    employees: [],
    selectedEmployees: [],
    // projects
    projects: [],
    // tasks
    events: [],
    // department
    filter: {
      employees: [],
      workflow_stage_id: "!4,!9",
      is_archive: ['0'],
      inRangeProjectsId: [],
    },
    // handle users load
    isLoading: false,
    // handle edit/create modal show
    modalShow: false,
    // handle delete/complete modal show
    warningModalShow: false,
    // handle error in the create/edit modal
    formError: null,
    // handle error in the calendar
    error: null,
    // used in the delete
    event: null,
    // handle success messages
    success: null,
    // expected values for option are delete or completed
    option: null,
    // below are the attributes that handles the tasks API calls (max limit = 100)
    limit: 100,
    date: getCurrentDate(),
    start_week: dateFormat(startOfWeekDate(getCurrentDate()), "YYYY-MM-DD"),
    end_week: dateFormat(
      endOfWeekDate(addDays(getCurrentDate(), 7)),
      "YYYY-MM-DD"
    )
  })

  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const generateTimeline = (strDate, events, projects) => {
    var date = new Date(strDate);
    // 08:00
    const startHourWithNoEstimation = 28800;
    // 09:00 start of a working day
    const startHour = 32400;
    // 18:00 end of a working day
    const endHour = 64800;
    // 9 hours per day
    const workingHours = 32400;
    var startTimeWithNoEstimation = new Date(startHourWithNoEstimation * 1000)
      .toISOString()
      .substr(11, 8);
    var startTime = new Date(startHour * 1000).toISOString().substr(11, 8);
    var duration = startHour;
    let timeLineEvents = events.map((event) => {
      var endTime = 0;
      if (event.time_estimate) {
        duration = duration + event.time_estimate;
        event.plan_start_date = new Date(`${strDate} ${startTime}`);
        if (duration > endHour) {
          duration = duration - endHour;
          var days = Math.ceil(duration / workingHours);
          if (dateFormat(strDate, 'dddd') === "Friday") days = days + 2
          duration = duration % workingHours;
          endTime = new Date((startHour + duration) * 1000)
            .toISOString()
            .substr(11, 8);
          event.deadline = new Date(
            `${moment(date.setDate(date.getDate() + days)).format(
              "YYYY-MM-DD"
            )} ${endTime}`
          );
        } else {
          endTime = new Date(duration * 1000).toISOString().substr(11, 8);
          event.deadline = new Date(`${strDate} ${endTime}`);
        }
        startTime = endTime;
      } else {
        endTime = startTimeWithNoEstimation;
        event.plan_start_date = new Date(
          `${strDate} ${startTimeWithNoEstimation}`
        );
        event.deadline = new Date(`${strDate} ${endTime}`);
      }

      event.event_color = getRandomColor();
      event.project_name = projects.find(
        (project) => project.id == event.model_id
      ).name;
      return event
    });
    return timeLineEvents
  };

  const groupByProperty = (resourceEvents, property) => {
    return resourceEvents.reduce(function (events, event) {
      let key =
        property === "plan_start_date"
          ? event[property].substr(0, 10)
          : event[property];
      if (!events[key]) {
        events[key] = [];
      }
      events[key].push(event);
      return events;
    }, {});
  };

  const buildTimelinePerUser = (user, events, projects) => {
    var eventsPerUser = events.filter((event) => {
      if (event.responsible_id === user.id) {
        return event;
      }
    });
    var groupedEvents = groupByProperty(eventsPerUser, "plan_start_date");
    var timeLineEvents = []
    Object.entries(groupedEvents).forEach(([date, events]) => {
      timeLineEvents = timeLineEvents.concat(generateTimeline(date, events, projects))
    });
    return timeLineEvents
  };

  const buildEvents = (rawEvents, users, projects) => {
    var inRangeEvents = [];
    projects.forEach((project) => {
      rawEvents.forEach((event) => {
        if (event.model_id == project.id) {
          if (
            !inRangeComparisonIncludeEdges(
              stringToDate(dateFormat(event.plan_start_date, 'YYYY-MM-DD')),
              stringToDate(project.startdate),
              stringToDate(project.enddate)
            )
          ) {
            if (event.plan_start_date !== "0000-00-00 00:00:00" && event.plan_start_date !== "") {
              event.plan_start_date = project.startdate;
              inRangeEvents.push(event);
            }
          } else {
            inRangeEvents.push(event);
          }
        }
      });
    });
    let timeLineEvents = []
    users.forEach((user) => {
      timeLineEvents = timeLineEvents.concat(buildTimelinePerUser(user, inRangeEvents, projects));
    });
    return timeLineEvents
  };



  const updateInRangeProjectsId = (projects) => {
    var inRangeProjectsId = [];
    projects.forEach((project) => {
      if (
        inRangeComparisonIncludeEdges(
          stringToDate(project.startdate),
          stringToDate(mainState.start_week),
          stringToDate(mainState.end_week)
        ) ||
        inRangeComparisonIncludeEdges(
          stringToDate(project.enddate),
          stringToDate(mainState.start_week),
          stringToDate(mainState.end_week)
        ) ||
        (stringToDate(project.startdate) < stringToDate(mainState.start_week) &&
          stringToDate(project.enddate) > stringToDate(mainState.end_week))
      ) {
        return inRangeProjectsId.push(project.id);
      }
    });
    setMainState(prev => ({ ...prev, filter: { ...prev.filter, inRangeProjectsId: inRangeProjectsId } }));
  };

  const getUpdatedEvents = (employees, projects) => {
    setMainState(prev => ({ ...prev, isLoading: true }));
    getAllTasks(mainState.filter).then((tasks) => {
      if (tasks) {
        const timeLineEvents = buildEvents(tasks, employees, projects);
        setMainState(prev => ({
          ...prev,
          events: timeLineEvents,
          isLoading: false,
        }));
      }
    });
  }

  const getCalendarData = async () => {
    setMainState(prev => ({ ...prev, isLoading: true }));
    const projects = await getAllProjects(mainState.filter)
    const users = await getAllUsers(mainState.filter)
    if (projects && users) {
      setMainState(prev => ({
        ...prev,
        employees: users,
        selectedEmployees: users,
        projects: projects,
        isLoading: false,
      }));
    }
    else {
      setMainState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const handleFilterData = (filterData) => {
    setMainState(
      (prevState) => ({
        ...prevState,
        filter: {
          ...prevState.filter,
          employees: filterData.employee_id
        },
      })
    );
  };

  const handleWeekNavigation = (date) => {
    setMainState(
      (prevState) => ({
        ...prevState,
        events: [],
        filter: {
          ...prevState.filter,
          inRangeProjectsId: []
        },
        date: date,
        start_week: dateFormat(startOfWeekDate(date), "YYYY-MM-DD"),
        end_week: dateFormat(endOfWeekDate(addDays(date, 7)), "YYYY-MM-DD"),
      })
    );
  };


  useEffect(() => {
    getCalendarData();
  }, [])

  useEffect(() => {
    if (mainState?.projects && mainState.projects.length !== 0) {
      updateInRangeProjectsId(mainState.projects);
    }
  }, [mainState.start_week, mainState.end_week, mainState.projects])

  useEffect(() => {
    if (mainState?.filter.inRangeProjectsId && mainState.filter.inRangeProjectsId.length !== 0 && mainState?.employees && mainState.employees.length !== 0 && mainState?.projects && mainState.projects.length !== 0) {
      getUpdatedEvents(mainState.employees, mainState.projects)
    }
  }, [mainState.filter.inRangeProjectsId])

  useEffect(() => {
    if (mainState.filter?.employees && mainState.filter.employees.length !== 0 && mainState?.employees && mainState.employees.length !== 0 && mainState?.projects && mainState.projects.length !== 0) {
      var selectedEmployees = mainState.employees.filter(employee => { if (mainState.filter.employees.includes(employee.id)) return employee })
      setMainState((prevState) => ({
        ...prevState,
        selectedEmployees: selectedEmployees,
      }));
      getUpdatedEvents(selectedEmployees, mainState.projects)
    }
  }, [mainState.filter])

  useEffect(() => {
    const { error } = mainState;

    if (error) {
      // change the error state after three second
      setTimeout(async () => {
        setMainState((prevState) => ({
          ...prevState,
          error: null,
        }));
      }, 3000);
    }
  }, [mainState.error])


  useEffect(() => {
    const { success } = mainState;
    if (success) {
      setTimeout(async () => {
        setMainState((prevState) => ({
          ...prevState,
          success: null,
        }));
      }, 3000);
    }
  }, [mainState.success])

  const dismissError = () => {
    setMainState((prevState) => ({
      ...prevState,
      error: null,
    }));
  };

  const dismissSuccess = () => {
    setMainState((prevState) => ({
      ...prevState,
      success: null,
    }));
  }

  const eventStyleGetter = (event, start, end, isSelected) => {
    var style = {
      backgroundColor: event.event_color ? event.event_color : "",
      opacity: 0.8,
      display: "block",
    };
    return {
      style: style,
    };
  };

  const { views, ...otherProps } = useMemo(() => ({
    views: {
      week: WorkWeek,
    },
  }), [])

  return (
    <div className="main-container">
      <TaskSchedularFilter
        submitFilterValue={handleFilterData}
        isLoadingSchedular={mainState.isLoading}
      />
      {mainState.isLoading ? (
        <PageLoader fullPage={true} />
      ) : (
        <div className="content">

          {mainState.success && (
            <Alert
              variant="success"
              className="top-right"
              onClose={() => dismissSuccess()}
              dismissible
            >
              {mainState.success}
            </Alert>
          )}

          {mainState.error && (
            <Alert
              variant="danger"
              className="top-left"
              onClose={() => dismissError()}
              dismissible
            >
              Error: {mainState.error.message}
            </Alert>
          )}

          {mainState?.selectedEmployees && mainState.selectedEmployees.length !== 0 && mainState?.events && (
            <Calendar
              className="pt-4"
              popup
              localizer={localizer}
              events={mainState.events}
              titleAccessor="name"
              tooltipAccessor="name"
              startAccessor="plan_start_date"
              endAccessor="deadline"
              resourceAccessor="responsible_id"
              resources={mainState.selectedEmployees}
              resourceIdAccessor="id"
              resourceTitleAccessor="name"
              defaultView="week"
              views={views}
              step={30}
              showMultiDayTimes={true}
              defaultDate={mainState.date}
              eventPropGetter={eventStyleGetter}
              min={new Date(0, 0, 0, 8, 0, 0)}
              max={new Date(0, 0, 0, 19, 0, 0)}
              onNavigate={(date) => handleWeekNavigation(date)}
              components={{
                event: (props) => (
                  <Event
                    {...props}
                  />
                ),
              }}
              {...otherProps}
            />
          )}
        </div>
      )
      }
    </div>
  )
}

export default FunctionCalendar