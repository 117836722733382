import { Redirect, Route } from "react-router-dom"
import { useContext } from "react"
import { AuthContext } from "../providers/AuthProvider/AuthContext"
import { loginRoute, unAuthorizedRoute } from "../constants/app-routes"
import { admins } from "../constants/admins"

export const AdminsRoute = ({ component: Component, ...rest }) => {
  const { user, isLoggedIn } = useContext(AuthContext)
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn && user) {
          if (admins.includes(user.id)) {
            return (
              <Component {...props} />
            )
          }
          else {
            return <Redirect to=
              {{
                pathname: unAuthorizedRoute
              }} />
          }
        }
        else {
          return <Redirect to=
            {{
              pathname: loginRoute,
              state: { from: props.location }
            }} />
        }
      }
      }
    />
  )
}