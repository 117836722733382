import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import './App.scss';
import Login from "./components/auth/GoogleLogin";
import { reportRoute, taskSchedularRoute, loginRoute, homeRoute, unAuthorizedRoute, agileReportRoute } from "./constants/app-routes";
import TaskSchedular from "./pages/task-schedular/task-schedular.page";
import Report from "./pages/report/report.page";
import { ProtectedRoute } from "./routes/protected.route";
import { AdminsRoute } from "./routes/admins.route";
import UnAuthorized from "./pages/unAuthorized/UnAuthorized";
import SideBar from "./components/SideBar/SideBar";
import DevReport from "./pages/DevReport/DevReport.page";


function App() {

  return (
    <div className="container-fluid pr-0">
      <Router>
        {/* This switch is used to specify which pages/routes include a sideBar */}
        <Switch>
          <Route exact path={reportRoute} children={<SideBar />} />
          <Route exact path={agileReportRoute} children={<SideBar />} />
          <Route exact path={taskSchedularRoute} children={<SideBar />} />
          <Route exact path={unAuthorizedRoute} children={<SideBar />} />
        </Switch>
        <Switch>
          <Route exact path={loginRoute} component={Login} />
          <AdminsRoute exact path={reportRoute} component={Report} />
          <AdminsRoute exact path={agileReportRoute} component={DevReport} />
          <ProtectedRoute exact path={taskSchedularRoute} component={TaskSchedular} />
          <Route exact path={unAuthorizedRoute} component={UnAuthorized} />
          <Redirect exact from={homeRoute} to={taskSchedularRoute} />
          <Route component={() => {
            window.location.href = "https://addbloom.com/";
            return null;
          }} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;