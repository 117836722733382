import { OverlayTrigger, Popover, Row, Col } from "react-bootstrap";

const Event = (props) => {
    let popoverClickRootClose = (
      // this functionality is taken from the react-bootstrap
      <Popover id="popover-trigger-click-root-close" style={{ zIndex: 100 }}>
        {/* <Popover.Title as="h3">{props.event.name}</Popover.Title> */}
        <Popover.Content>
          <Row>
            <Col as="h6" sm="4">
              Project:
            </Col>
            <Col sm="8">{props.event.project_name}</Col>
          </Row>
          <Row>
            <Col as="h6" sm="4">
              Task:
            </Col>
            <Col sm="8">{props.event.name}</Col>
          </Row>
        </Popover.Content>
      </Popover>
    )
    return (
        <OverlayTrigger
          trigger="click"
          rootClose
          container={this}
          placement="auto"
          overlay={popoverClickRootClose}
        >
          <span className="fill-all-event-container">
            <div>
              {props.event.project_name} - {props.event.name}
            </div>
          </span>
        </OverlayTrigger>
      );
    };

    export default Event