import React from "react";
import { FormCheck } from "react-bootstrap";
import "./primary-checkbox.scss";

const PrimaryCheckbox=({
  id,
  label,
  value,
  text,
  onChange,
  defaultChecked,
  isDisabled
}) => {

  return (
    <div className="primary-checkbox-container">
      <div className="primary-checkbox-holder">
        <FormCheck
          id={id}
          label={label}
          type="checkbox"
          value={value}
          defaultChecked={defaultChecked}
          disabled={isDisabled}
          onChange={(event) => {
            onChange && onChange(event);
          }}
        />
      </div>

      <label>{text}</label>
    </div>
  );
};

export default PrimaryCheckbox;
