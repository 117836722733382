import { departments, userDepartment } from "../constants/user-department"
import { units, userUnit } from "../constants/user-units";

export const getUserDepartment = (userId) => {
    var department= 'N/A';
    Object.keys(departments).every(key => {
        if(userDepartment[key].includes(userId)) {
            department = departments[key]
            return false;
        }
        return true;
    })
    return department
}

export const getUserUnit = (userId) => {
    var unit= 'N/A';
    Object.keys(units).every(key => {
        if(userUnit[key].includes(userId)) {
            unit = units[key]
            return false;
        }
        return true;
    })
    return unit
}

export const getUserObj = (users, userId) => {
  return users.find(user => user.value === userId )
}

const getDepartments = () => {
    return Object.keys(departments).map((key) => {
      return { value: userDepartment[key], label: departments[key] };
    });
  };

export const getEmployeeOptions = (employees) => {
    return Object.keys(employees).map((employee) => {
      return {
        label:
          employees[employee].first_name + " " + employees[employee].last_name,
        value: employees[employee].id,
      };
    });
  };

export const getMergedEmployeeOptions = (employees) => {
    const emp = getEmployeeOptions(employees)
    const depart = getDepartments()
    return emp.concat(depart);
  };
