export const getProjectObj = (projects, projectId) => {
    return projects.find(project => project.id === projectId )
}


export const getProjectOptions = (projects) => {
    return Object.keys(projects).map((obj) => {
      return {
        label: projects[obj].name,
        value: projects[obj].id,
        manager: projects[obj].manager_id,
        isRecurring: projects[obj].cf_72 === '1' ? 'yes' : 'no',
        customerId: projects[obj].customer_id
      };
    });
  };