import React, { useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav'
import { agileReportRoute, reportRoute, taskSchedularRoute } from '../../constants/app-routes'
import useAuth from '../../hooks/useAuth'
import { SideMenuContext } from '../../providers/SideMenuProvider/SideMenuContext'
import { AuthContext } from '../../providers/AuthProvider/AuthContext'
import "./sideBar.style.scss";
import { admins } from '../../constants/admins'


function SideBar() {
    const { user } = useContext(AuthContext)
    const { expanded, setExpanded } = useContext(SideMenuContext)
    const { logout } = useAuth()
    const history = useHistory();
    const location = useLocation();
    const [ selected, setSelected ] = useState(location.pathname ? location.pathname : taskSchedularRoute);

    const onSelect = (value) => {
        setSelected(value);
        if (value === "") {
            logout()
        } else {
            history.push(value);
        }
    };

    const onToggle = (value) => {
        setExpanded(value)
    }

    const navItemClass = user && admins.includes(user.id) ? '' : 'disable'

    return (
        <SideNav onSelect={onSelect} onToggle={onToggle} expanded={expanded}>
            <SideNav.Toggle />
            <SideNav.Nav selected={selected}>
                <NavItem eventKey={taskSchedularRoute}>
                    <NavIcon>
                        <i className="fa fa-fw fa-calendar" style={{ fontSize: '1.75em' }} />
                    </NavIcon>
                    <NavText>
                        Home
                    </NavText>
                </NavItem>
                <NavItem eventKey={reportRoute} navitemClassName={navItemClass}>
                    <NavIcon>
                        <i className="fa fa-fw fa-bar-chart" style={{ fontSize: '1.75em' }} />
                    </NavIcon>
                    <NavText>
                        Reports
                    </NavText>
                </NavItem>
                <NavItem eventKey={agileReportRoute} navitemClassName={navItemClass}>
                    <NavIcon>
                        <i className="fa fa-fw fa-line-chart" style={{ fontSize: '1.75em' }} />
                    </NavIcon>
                    <NavText>
                        Agile Reports
                    </NavText>
                </NavItem>
                <NavItem eventKey="">
                    <NavIcon>
                        <i className="fa fa-fw fa-sign-out" style={{ fontSize: '1.75em' }} />
                    </NavIcon>
                    <NavText>
                        Logout
                    </NavText>
                </NavItem>
            </SideNav.Nav>
        </SideNav>

    )
}

export default SideBar
