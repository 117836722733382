import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { GoogleLogin } from 'react-google-login';
import { Alert, Image } from 'react-bootstrap';
import { homeRoute, taskSchedularRoute } from '../../constants/app-routes';
import { useContext } from 'react';
import { AuthContext } from '../../providers/AuthProvider/AuthContext';
import useAuth from '../../hooks/useAuth';
import { logenAxiosInstance } from '../../config/axios-config';
import SectionLoader from '../loaders/section-loader/section-loader.component';


function Login() {
    const history = useHistory();
    const { isLoggedIn } = useContext(AuthContext)
    const { login } = useAuth()
    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [error, setError] = useState(null)
    const limit = 100;

    const onLoginSuccess = (res) => {
        // list of userss
        logenAxiosInstance.get(`/core/user/list?limit=${limit}&api_key=${process.env.REACT_APP_API_AUTH_KEY}`)
            .then((response) => {
                if (response.status === 200) {
                    // get the matching user index
                    const userIndex = response.data.response.items.findIndex(el => el.username === res.profileObj.email);
                    if (userIndex !== -1) {
                        // call the login function in the parents 
                        login(response.data.response.items[userIndex]);
                        history.push(taskSchedularRoute);
                    } else {
                        history.push(homeRoute);
                    }
                }
            })
            .catch(error => {
                setError(error)
            })
            .finally(() => setIsLoading(false));
    };

    const dismissError = () => {
        setError(null)
    }

    const handleAutoLoad = () => {
        setIsDisabled(false)
    }

    useEffect(() => {
        if (isLoggedIn) {
            history.push(taskSchedularRoute);
        }
    }, [isLoggedIn])
    
    return (
        <div className="d-flex justify-content-center align-items-center text-center vh-100">
            {error &&
                <Alert variant="danger" className="top-left" onClose={() => dismissError()} dismissible>
                    Error: {error.message}
                </Alert>
            }
            {isLoading ?
                <SectionLoader />
                :
                <div>
                    <div>
                        <Image src="/assets/AddBloom-logo.svg" className='pb-4' fluid />
                    </div>
                    <div className='pb-4'>Log in with google in order to continue to the platform</div>
                    <div className='pb-4' onClick={() => !isDisabled && setIsLoading(true)}>
                        <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                            buttonText="Continue with Google"
                            onSuccess={onLoginSuccess}
                            onAutoLoadFinished={handleAutoLoad}
                            disabled={isDisabled}
                            className='px-4'
                            onFailure={(error) => {
                                setIsLoading(false)
                                setError(error)
                            }
                            }
                            cookiePolicy={'single_host_origin'}
                        />
                    </div>
                </div>
            }
        </div>
    );
}
export default Login;