import moment from 'moment';

export const getDate = () => {
    return moment();
}

export const dateFormat = (dateString, format) => {
    return dateString && moment(dateString).format(format);
}

export const dateIsBefore = (fromDate, toDate) => {
    return moment(fromDate).isBefore(toDate);
}

export const dateIsAfter = (fromDate, toDate) => {
    return moment(fromDate).isAfter(toDate);
}

export const secondToHours = (secs) => {
    return moment.utc(secs*1000).format('HH:mm:ss')
}

export const secondToMinutes = (secs) => {
    return Math.round(secs/60)
}

export const stringToDate = (dateString) => {
    return moment(dateString);
}

export const inRangeComparisonIncludeEdges = (compareDate, startDate, endDate) => {
    return compareDate.isBetween(startDate, endDate, undefined, '[]');
}

export const startOfWeekDate = (date) => {
    return moment(date).startOf("isoWeek")
}

export const endOfWeekDate = (date) => {
    return moment(date).endOf("isoWeek")
}

// export const formatDuration = (durationInMinutes) => {
//     return moment.duration(durationInMinutes, "minutes").humanize();
// }

// export const getDateAfter = (numberOfDays, format) => {
//     return moment.utc().add(numberOfDays, 'days').format(format);
// }.add(5, 'days')

export const getCurrentDate = () => {
    return new Date();
}

export const toDate = (dateString) => {
    return new Date(dateString);
}

export const addDays = (date, numberOfDays) => {
    return moment(date).add(numberOfDays, 'days');
}

export const addYears = (date, numberOfYears) => {
    return moment(date).add(numberOfYears, 'years');
}

// export const getUTCDateTime = (localDate) => {
//     return moment(localDate).utc().format('YYYY-MM-DD[T]hh:mm:ss');
// }

// export const getLocalDateTime = (utcDate) => {
//     return moment.utc(utcDate).local().format('YYYY-MM-DD[T]hh:mm:ss');
// }

// export const getLocalDateTimeFromOffset = (offsetDate, offset) => {
//     return moment.parseZone(`${offsetDate}${offset}`).local().format('YYYY-MM-DD[T]hh:mm:ss');
// }

// export const getOffsetDateTime = (localDate, offset) => {
//     return moment(localDate).utcOffset(offset).format('YYYY-MM-DD[T]hh:mm:ss');
// }

// export const getCurrentTimeZone = () => {
//     return moment().format('Z');
// }