import { getAccountObj } from "./account-management";
import { secondToMinutes } from "./date-time-management";
import { getUserDepartment, getUserObj, getUserUnit } from "./user-management";

export const generateReport = (timelog, tasks, filter, employees, projects, customers) => {
    console.log("customers", customers)
    var report = [];
    if (filter?.projects?.length && filter?.employees?.length) {
        filter.projects.forEach((project) => {
            filter.employees.forEach((employee) => {
                timelog
                    .filter(
                        (taskTimeLog) =>
                            taskTimeLog.user_id === employee.value &&
                            project.value === taskTimeLog.project.value
                    )
                    .forEach((filteredTask) => {
                        const reportObj = {
                            project_name: project.label,
                            is_recurring: project.isRecurring,
                            project_manager: getUserObj(employees, project?.manager)?.label,
                            customer_name: getAccountObj(customers, project?.customerId)?.label ?? 'N/A',
                            task_name: tasks.find(
                                (task) => task.value === filteredTask.timesheet_model_id
                            )?.label,
                            employee_name: employee.label,
                            time_spent_minutes: secondToMinutes(filteredTask.time_spent),
                            unit: getUserUnit(employee?.value),
                            department: getUserDepartment(employee?.value),
                            complete_date: filteredTask.created_date,
                            description: filteredTask.description
                        };
                        report.push(reportObj);
                    });
            });
        });
    } else if (filter?.projects?.length && !filter?.employees?.length) {
        filter.projects.forEach((project) => {
            timelog
                .filter((taskTimeLog) => project.value === taskTimeLog.project.value)
                .forEach((filteredTask) => {
                    const employee = employees.find(
                        (employee) => employee.value === filteredTask.user_id
                    )
                    const reportObj = {
                        project_name: project.label,
                        is_recurring: project.isRecurring,
                        project_manager: getUserObj(employees, project?.manager)?.label,
                        customer_name: getAccountObj(customers, project?.customerId)?.label ?? 'N/A',
                        task_name: tasks.find(
                            (task) => task.value === filteredTask.timesheet_model_id
                        )?.label,
                        employee_name: employee?.label,
                        time_spent_minutes: secondToMinutes(filteredTask.time_spent),
                        unit: getUserUnit(employee?.value),
                        department: getUserDepartment(employee?.value),
                        complete_date: filteredTask.created_date,
                        description: filteredTask.description
                    };
                    report.push(reportObj);
                });
        });
    } else if (!filter?.projects?.length && filter?.employees?.length) {

        timelog.forEach((log) => {
            const task = tasks.find((task) => task.value === parseInt(log.timesheet_model_id));
            const project = projects.find(
                (project) => project.value === task?.projectId
            )
            if (project) {
                const employee = employees.find(
                    (employee) => employee.value === log.user_id
                )
                const reportObj = {
                    project_name: project?.label,
                    is_recurring: project?.isRecurring,
                    project_manager: getUserObj(employees, project?.manager)?.label,
                    customer_name: getAccountObj(customers, project?.customerId)?.label ?? 'N/A',
                    task_name: task?.label,
                    employee_name: employee?.label,
                    time_spent_minutes: secondToMinutes(log.time_spent),
                    unit: getUserUnit(employee?.value),
                    department: getUserDepartment(employee.value),
                    complete_date: log.created_date,
                    description: log.description
                };
                report.push(reportObj);
            }
        });
    }
    return report
};