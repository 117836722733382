import { useCallback, useContext } from 'react';
import { useGoogleLogout } from 'react-google-login'
import { AuthContext } from '../providers/AuthProvider/AuthContext';
import storage from '../helpers/storage';

const useAuth = () => {
  const { syncWithLocalStorage } = useContext(AuthContext)
  const { setToLocalStorage, removeFromLocalStorage } = storage()

  const onLogoutSuccess = () => {
    console.log('logout successfully');
    removeFromLocalStorage('loggedIn')
    removeFromLocalStorage('user')
    syncWithLocalStorage()
  };

  const onFailure = () => {
    console.log('logout fail');
  };
  
  // signOut hook to logout from google login
  const { signOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    onLogoutSuccess: onLogoutSuccess,
    onFailure: onFailure,
  });

  const login = useCallback((res) => {
    // set the expiry to 5 day = 432000000 ms
    setToLocalStorage("loggedIn", true, 432000000);
    setToLocalStorage("user", res, 432000000);
    syncWithLocalStorage()
  },[]);

  const logout = useCallback(() => {
    signOut()
  },[]);

  return {
    login,
    logout
  }
}

export default useAuth
