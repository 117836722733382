export const departments = {
  1: "Designer",
  2: "Account Manager",
  3: "Social Media Manager",
  4: "Account Lead",
  5: "Media",
  6: "Developer",
  7: "UX/UI",
};

export const userDepartment = {
  1: [
    176166, // Rana
    169301, // Kristel
    175010, //
    176167, // Cindy
    187583, // Elodie
    180964, // Ghady
    197328, // Sarah Tarhini
    197331, // Hani
    197327, // Michel
    202521, // Luanna
    220873, // Garen
    234069, // Bernard Badran
  ],
  2: [
    176171, // Helen
    176170, // Louma
    170314, // Vanessa
    188210, // Maya
  ],
  3: [
    176184, // Rita Moukarzel
    181649, // Reem Hannoun
    181650, // Tia Toubia
    181648, // Cynthia Bechara
    176193, //
    196911, // Jouanna
    197038, // Lucie
    197652, // Sarah Lichaa Khoury
    197654, // Mia maroun
    197653, // Alaa kanaan
    230054, // Sophie Abou Jaoude
    237715, // Nour Haber
  ],
  4: [
    176179, // Stephanie
    175673, // Marc
    169304, // Rita Abo khalil
  ],
  5: [
    176196, // Ghada Khoury
    205383, // Joana Karam
    183944, // Simon El Hajj
    255160, // Khalil El Deghel
  ],
  6: [
    176176, // Simon Sayssa
    183879, // Chady Badour
    176174, // Patricia Abdallah
    168420, // Joey Azar
    176173, // Alexi Abou Sleiman
    195083, // Mario Kareh
  ],
  7: [
    173004, // Sascha
  ],
};
