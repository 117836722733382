import SectionLoader from '../section-loader/section-loader.component'
import './page-loader.style.scss'


const PageLoader = ({ fullPage = false, text }) => {
    return (
        <div className={`page-loader${fullPage ? ' full-page' : ''}`}>
            <SectionLoader text={text} />
        </div>
    )
}

export default PageLoader;
