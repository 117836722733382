import { Redirect, Route } from "react-router-dom"
import { useContext } from "react";
import { loginRoute } from "../constants/app-routes";
import { AuthContext } from "../providers/AuthProvider/AuthContext";

export const ProtectedRoute = ({ component:Component, ...rest }) => {
  const { isLoggedIn, user } = useContext(AuthContext)
    return (
      <Route
        {...rest}
        render={(props) => { 
          if(isLoggedIn && user) { 
           return (
              <Component {...props} />
           )
          }
          else
          {
            return <Redirect to=
              {{
                pathname: loginRoute,
                state: { from: props.location }
              }}/>
          }
        }     
        }
      />
    )
  }