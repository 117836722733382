import { getAppSettings } from "../config/app-settings";
import axiosInstance from "../config/axios-config";

const limit = 100;

const getTasks = (page, filter) => {
  var body = {
    limit: limit,
    page: page,
    api_key: getAppSettings().apiKey,
  };

  if (filter.tasks && filter.tasks.length !== 0) {
    body['filter[id]'] = filter.tasks.toString()
  }
  if (filter.projects && filter.projects.length !== 0) {
    body['filter[project_id]'] = filter.projects.map(project => project.value).toString()
  }

  return axiosInstance
    .get(`${getAppSettings().apiUrl}/agile/issues/list`, {
      params: body,
    })
    .then((response) => {
      return response.data.response;
    })
    .catch((error) => {
      console.log(error)
    });
};

export const getAllIssues = (filter = {}) => {
  var tasks = [];
  var result = 0;
  var page = 1;
  return appendTasksFromServer(tasks, result, page, filter);
};

const appendTasksFromServer = async (tasks, result, page, filter) => {
  await getTasks(page, filter).then(async (response) => {
    if (response) {
      response.items.map((task) => {
        return tasks.push(task);
      });
      result = response.count;
      page = page + 1;
      if (result === response.total_result) {
        return tasks;
      } else {
        const nbOfRequests = Math.ceil(response.total_result / limit);
        if (nbOfRequests > 1) {
          var tasksRequests = [];
          while (page <= nbOfRequests) {
            tasksRequests.push(getTasks(page, filter));
            page = page + 1;
          }
          await Promise.all(tasksRequests).then((res) => {
            Object.keys(res).map((index) => {
              if (res[index]) {
                res[index].items.map((task) => {
                  return tasks.push(task);
                });
                result = result + res[index].count;
                if (result === res[index].total_result) {
                  return tasks;
                }
              }
            });
          });
        }
      }
    }
  });
  return tasks;
};