export const sortAlphabetically = (arrOfObj, property) => {
  return arrOfObj.sort((a, b) =>
    a[property] > b[property] ? 1 : b[property] > a[property] ? -1 : 0
  );
};

export const getObjOptions = (objs) => {
  return Object.keys(objs).map((obj) => {
    return {
      label: objs[obj].name,
      value: objs[obj].id,
    };
  });
};