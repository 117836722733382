import { getAppSettings } from "../config/app-settings";
import axiosInstance from "../config/axios-config";

const limit = 100;

export const getAllProjects = (filter = {}) => {
    var projects = [];
    var result = 0;
    var page = 1;
    return appendNewProjectsFromServer(projects, result, page, filter)
};

const getProjects = (page, filter = {}) => {
    var body = {
    limit: limit,
    page: page,
    api_key: getAppSettings().apiKey,
    };

    if (filter.is_archive && filter.is_archive.length === 1) {
        body["filter[is_archive]"]= filter.is_archive.toString()
    }
    return axiosInstance
    .get(`/st/projects/list`, {
        params: body,
    })
    .then((response) => {
        return response.data.response;
    })
    .catch((error) => {
        console.log(error)
    });
};

const appendNewProjectsFromServer = (projects, result, page, filter) => {
    return getProjects(page, filter).then((response) => {
    if (response) {
        response.items.map((project) => {
            return projects.push(project);
        });

        result = result + response.count
        page = page + 1

        if (result !== response.total_result) {
        return appendNewProjectsFromServer(projects, result, page, filter);
        }
        return projects;
    }
    });
};