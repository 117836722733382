import { getAppSettings } from "../config/app-settings";
import axiosInstance from "../config/axios-config";

const limit = 100;

export const getAllUsers = (filter) => {
    var users = [];
    var result = 0;
    var page = 1;
    return appendNewUsersFromServer(users, result, page, filter)
  };

const getUsers = (page, filter) => {
    var params = {
      limit: limit,
      page: page,
      api_key: getAppSettings().apiKey,
    };

    if (filter?.employees  && filter.employees?.length !== 0) {
      params["filter[id]"] = filter.toString() 
    }

    return axiosInstance
      .get(
        `/core/user/list`,{
          params: params
        }
      )
      .then((response) => {
        return response.data.response
      })
      .catch((error) => {
        console.log(error)
      });
  };

const appendNewUsersFromServer = (users, result, page, filter) => {
    return getUsers(page, filter).then((response) => {
      if (response) {
        const data = response.items
        Object.keys(data).map((user) => {
          data[user].id = parseInt(data[user].id);
          return users.push(data[user])
        })

        result = result + response.count
        page = page + 1

        if (result !== response.total) {
          return appendNewUsersFromServer(users, result, page);
        }
        return users;
      }
    });
  };