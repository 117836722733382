import axios from 'axios';
import { getAppSettings } from './app-settings';
import storage from '../helpers/storage';

export const logenAxiosInstance = axios.create({
    baseURL: getAppSettings().apiUrl
});

const axiosInstance = axios.create({
    baseURL: getAppSettings().apiUrl
});

axiosInstance.interceptors.request.use(async (request) => {
    const isLoggedIn = storage().getFromLocalStorage('loggedIn');
    if (!isLoggedIn) {
        window.location.reload();
    } 
    // delay for 0.75 second
    await new Promise(resolve => setTimeout(resolve, 750));
    return request;
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.response?.status === 429) {
            // delay for 0.75 second
            await new Promise(resolve => setTimeout(resolve, 750));
            return axiosInstance(error.config);
        }
        return Promise.reject(error);
    })

export default axiosInstance;