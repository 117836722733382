const storage = () => {
    // function that get data from local storage and check expiry
    const getWithExpiry = (key) => {
        const itemStr = localStorage.getItem(key)
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key)
            return null
        }
        return item.value
    }

    // function that store data and set expiry in the local storage
    // by default time to live is 30 days = 2592000000
    const setWithExpiry = (key, value, ttl=2592000000) => {
        const now = new Date()
        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item))
    }

    const removeItem = (key) => {
        localStorage.removeItem(key)
    }

    return {
        getFromLocalStorage: getWithExpiry,
        setToLocalStorage: setWithExpiry,
        removeFromLocalStorage: removeItem
    }
}

export default storage;