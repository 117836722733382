import { getAppSettings } from "../config/app-settings";
import axiosInstance from "../config/axios-config";

const limit = 100;

const getTasks = (page, filter) => {
  var body = {
    limit: limit,
    page: page,
    "filter[model]": "project",
    // cf_69 is the custom field in the tasks for the "show" property
    // "filter[cf.field_69]": "1",
    api_key: getAppSettings().apiKey,
  };

  if (filter.workflow_stage_id) {
    body['filter[workflow_stage_id]'] = filter.workflow_stage_id
  }
  if (filter.tasks && filter.tasks.length !== 0) {
    body['filter[id]'] = filter.tasks.toString()
  }
  if (filter.projects && filter.projects.length !== 0) {
    body['filter[model_id]'] = filter.projects.map(project => project.value).toString()
  }
  if ((filter.employees && filter.employees.length !== 0)) {
    console.log('filter.employees', filter.employees)
    body["filter[responsible_id]"] = filter.employees.map(employee => employee?.value ?? employee).toString()
  }
  if (filter.inRangeProjectsId && filter.inRangeProjectsId.length !== 0) {
    body["filter[model_id]"] = filter.inRangeProjectsId.toString();
  }
  return axiosInstance
    .get(`${getAppSettings().apiUrl}/task/tasks/list`, {
      params: body,
    })
    .then((response) => {
      return response.data.response;
    })
    .catch((error) => {
      console.log(error)
    });
};

export const getAllTasks = (filter = {}) => {
  var tasks = [];
  var result = 0;
  var page = 1;
  return appendTasksFromServer(tasks, result, page, filter);
};

const appendTasksFromServer = async (tasks, result, page, filter) => {
  return getTasks(page, filter).then(async (response) => {
    if (response) {
      response.items.map((task) => {
        return tasks.push(task);
      });
      result = result + response.count
      page = page + 1;

      if (result !== response.total_result) {
        return appendTasksFromServer(tasks, result, page, filter);
      }
      return tasks;
    }
  });

};


export const getTask = (taskId) => {
  var body = {
    api_key: getAppSettings().apiKey,
  };
  return axiosInstance
    .get(`${getAppSettings().apiUrl}/task/tasks/get/${taskId}`, {
      params: body,
    })
    .then((response) => {
      return response.data.response;
    })
    .catch((error) => {
      console.log(error)
    });
};