import React, { useContext } from 'react'
import { SideMenuContext } from '../../providers/SideMenuProvider/SideMenuContext'
import { Container } from 'react-bootstrap'
import DevReportFilter from '../../components/filter/dev-report-filter/dev-report-filter.component'

const DevReport = () => {
    const { expanded } = useContext(SideMenuContext)

    const withSideBar = {
        marginLeft: expanded ? "224px" : "48px",
    };

    return (
        <Container style={withSideBar} fluid>
            <DevReportFilter />
        </Container>
    )
}

export default DevReport