import React,{ useState } from "react";
import { AuthContext } from "./AuthContext";
import storage from "../../helpers/storage";


const AuthProvider = ({ children }) => {
  const { getFromLocalStorage } = storage()
  const [isLoggedIn, setIsLoggedIn] = useState(getFromLocalStorage("loggedIn"));
  const [ user, setUser ] = useState(getFromLocalStorage("user") );

  const syncWithLocalStorage = () => {
    const isLoggedIn = getFromLocalStorage("loggedIn")
    const isLocalUser = getFromLocalStorage("user")  
    setIsLoggedIn(isLoggedIn)
    setUser(isLocalUser)
  }
  

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, syncWithLocalStorage }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider