import React, { useEffect, useState, memo } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  departments,
  userDepartment,
} from "../../../constants/user-department";
import { sortAlphabetically } from "../../../helpers/generic-functions";
import { getAllUsers } from "../../../services/employee.service";
import PrimaryDropdown from "../../dropdown/primary-dropdown/primary-dropdown.component";
import "./task-schedular-filter.style.scss";

const TaskSchedularFilter = ({ submitFilterValue, isLoadingSchedular }) => {
  const [employees, setEmployees] = useState([]);
  const [employeeSelectedOption, setEmployeeSelectedOption] = useState();
  const [department, setDepartment] = useState([]);
  const [departmentSelectedOption, setDepartmentSelectedOption] = useState();
  // const [departmentSelectedOption, setDepartmentSelectedOption] = useState({value: "1", label: "Designer"});
  const [filter, setFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEmoployeeOptions, setIsLoadingEmoployeeOptions] = useState(false);
  const [isLoadingDepartmentOptions, setIsLoadingDepartmentOptions] = useState(false);


  const handleEmployeeChange = (event) => {
    setDepartmentSelectedOption(null)
    setEmployeeSelectedOption(event)
    setFilter(prevState => ({
      ...prevState,
      employee_id: [event.value],
    }));
  };

  const handleDepartmentChange = (event) => {
    setEmployeeSelectedOption(null)
    setDepartmentSelectedOption(event)
    setFilter((prevState) => ({
      ...prevState,
      employee_id: userDepartment[event.value]
    }));
  };

  const getEmployees = () => {
    setIsLoadingEmoployeeOptions(true)
    getAllUsers()
    .then((users) => {
      if (users?.length) {
        const userData = users.map((user) => ({label: user.name, value: user.id}))
        setEmployees(userData);
      }
      setIsLoadingEmoployeeOptions(false)
    });
  };

  const getDepartmentData = () => {
    setIsLoadingDepartmentOptions(true)
    const departmentData = Object.keys(departments).map((key) => {
      return { value: key, label: departments[key] };
    });
    setDepartment(departmentData);
    setIsLoadingDepartmentOptions(false);
  };

    useEffect(() => {
      getEmployees();
      getDepartmentData();
    }, []);

    useEffect(() => {
      setIsLoading(isLoadingSchedular);
    }, [isLoadingSchedular]);

  const handleSubmit = () => {
      setIsLoading(true);
      submitFilterValue(filter);
  };

  const clearFilter = () => {
    setFilter({});
    setEmployeeSelectedOption(null)
    setDepartmentSelectedOption(null)
    setIsLoading(false);
  };
  
  return (
    <Container className="task-schedular-filter py-3" fluid>
      <Row className="task-schedular-filter-row"> 
        <Col sm={2} className="d-flex align-items-center justify-content-center">
          <Button
            variant="link"
            onClick={() => handleSubmit()}
            disabled={isLoading}
          >
                <i className="fa fa-filter pr-2" aria-hidden="true" />
                Filter
          </Button>
        </Col>
        <Col sm={4}>
          <PrimaryDropdown
            name="employee_id"
            placeholder="Select Department"
            selectedOption={departmentSelectedOption ?? ""}
            options={department?.length ? sortAlphabetically(department, "label") : []}
            isLoading={isLoadingDepartmentOptions}
            isDisabled={isLoadingDepartmentOptions}
            onChange={(event) => handleDepartmentChange(event)}
          />
        </Col>
        <Col sm={4}>
          <PrimaryDropdown
            name="employee_id"
            placeholder="Select Employee"
            selectedOption={employeeSelectedOption ?? ""}
            options={employees?.length ? sortAlphabetically(employees, "label") : []}
            isLoading={isLoadingEmoployeeOptions}
            isDisabled={isLoadingEmoployeeOptions}
            onChange={(event) => handleEmployeeChange(event)}
          />
        </Col>
        <Col sm={2} className="d-flex align-items-center justify-content-center">
          <Button
            variant="outline-danger"
            onClick={() => clearFilter()}
          >
            <i className="fa fa-times pr-2" aria-hidden="true" />
            Clear
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default memo(TaskSchedularFilter);
