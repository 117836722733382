import { useRef } from "react";
import Select from "react-select";
import "./primary-dropdown.style.scss";

const PrimaryDropdown = ({
  name,
  label,
  placeholder,
  options,
  selectedOption,
  onChange,
  multiple,
  isLoading,
  isDisabled
}) => {

    // isOptionSelected sees previous selectedOption after onChange
    const valueRef = useRef(selectedOption);
    valueRef.current = selectedOption;

    const selectAllOption = {
      value: "<SELECT_ALL>",
      label: "All Items"
    };
  
    const isSelectAllSelected = () =>
      valueRef.current.length === options.length;
  
    const isOptionSelected = option =>
      valueRef.current.some(({ value }) => value === option.value) ||
      isSelectAllSelected();
  
    const getOptions = () => [selectAllOption, ...options];
  
    const getValue = () =>
      isSelectAllSelected() ? [selectAllOption] : selectedOption;
  
    const change = (newValue, actionMeta) => {
      const { action, option, removedValue } = actionMeta;
  
      if (action === "select-option" && option.value === selectAllOption.value) {
        onChange(options, actionMeta);
      } else if (
        (action === "deselect-option" &&
          option.value === selectAllOption.value) ||
        (action === "remove-value" &&
          removedValue.value === selectAllOption.value)
      ) {
        onChange([], actionMeta);
      } else if (
        actionMeta.action === "deselect-option" &&
        isSelectAllSelected()
      ) {
        onChange(
          options.filter(({ value }) => value !== option.value),
          actionMeta
        );
      } else {
        onChange(newValue || [], actionMeta);
      }
    };

  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      overflowY: 'scroll',
      maxHeight: '150px'
    })
  }

  return (
    <div className="primary-dropdown-container">
      {label && <p className="label">{label}</p>}
      <div>
        <Select
          styles={customStyles}
          isOptionSelected={multiple ? isOptionSelected : ''}
          options={multiple ? getOptions() : options}
          value={multiple ? getValue() : selectedOption}
          name={name}
          placeholder={placeholder}
          onChange={multiple ? change : onChange}
          isMulti={multiple}
          isLoading={isLoading}
          isDisabled={isDisabled}
          isSearchable
        />
      </div>
    </div>
  );
};
export default PrimaryDropdown;
