import React,{ useState } from "react";
import { SideMenuContext } from "./SideMenuContext";
import storage from "../../helpers/storage";


const SideMenuProvider = ({ children }) => {
  const { getFromLocalStorage, setToLocalStorage } = storage();
  const [ expanded, setExpanded ] = useState(getFromLocalStorage("isExpanded"));


  const setIsExpanded = (value) => {
    setToLocalStorage("isExpanded", value);
    setExpanded(value)
  }


  return (
    <SideMenuContext.Provider value={{ expanded, setExpanded: setIsExpanded }}>
      {children}
    </SideMenuContext.Provider>
  )
}

export default SideMenuProvider