export const units = {
    1:"Unit 1",
    2:"Unit 2",
    3:"Unit 3",
}

export const userUnit = {
1:[
    188210, // Maya
    176171, // Helen
    180964, // Ghady
    197331, // Hani
    197327, // Michel
    202521, // Luanna
    220873, // Garen
]
,
2:[
    176179, // Stephanie
    176170, // Louma
    176166, // Rana
    197328, // Sarah T
]
,
3:[
    170314, // Vanessa
    169304, // Rita Abo khalil
    175673, // Marc
    176167, // Cindy
    187583, // Elodie
    169301, // Kristel
]
}