import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./primary-date-picker.style.scss";
import MaskedInput from 'react-text-mask'

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const PrimaryDatePicker = ({ selectedDate, onChange, placeholder }) => {
  const dropDownRef = useRef(null);

  return (
    <div className="primary-date-picker-container">
      <div className="primary-date-picker">
        <DatePicker
          ref={dropDownRef}
          customInput={<MaskedInput
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          />}
          showPopperArrow={false}
          className="primary-select"
          placeholderText={placeholder}
          selected={selectedDate}
          onChange={onChange}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
        <div
          className="primary-select-icon"
          onClick={() =>
            dropDownRef.current.setOpen(true)
          }
        >
          <i className="fa fa-fw fa-calendar" style={{ fontSize: "1.25em" }} />
        </div>
      </div>
    </div>
  );
};

export default PrimaryDatePicker;
