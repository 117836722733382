import { getAppSettings } from "../config/app-settings";
import axiosInstance from "../config/axios-config";
import { addYears, dateFormat } from "../helpers/date-time-management";

const limit = 100;

export const getAgileProjectTimeLog = (filter = {}, projectId) => {
    var timeLogs = [];
    var result = 0;
    var page = 1;
    return appendNewTimeLogFromServer(timeLogs, result, page, filter, projectId)
};

const getTimeLog = (page, filter, projectId) => {
    var body = {
    limit: limit,
    page: page,
    api_key: getAppSettings().apiKey
    };
    if (filter.employees && filter.employees.length !== 0) {
        body["filter[user_id]"] = filter.employees.map(employee => employee.value).toString();
    }
    if (filter.from_date || filter.to_date) {
        body["filter[created_date]"] = {
              start_date: filter.from_date ? filter.from_date : dateFormat(addYears(filter.to_date, -1), "YYYY-MM-DD"),
              end_date: filter.to_date ? filter.to_date: dateFormat(addYears(filter.from_date, 1), "YYYY-MM-DD"),
            }
    }

    return axiosInstance
    .get(`${getAppSettings().apiUrl}/agile/projects/${projectId}/timelogs/list`, {
        params: body,
    })
    .then((response) => {
        return response.data.response;
    })
    .catch((error) => {
        console.log(error)
    });
};

const appendNewTimeLogFromServer = (timeLogs, result, page, filter, projectId) => {
    return getTimeLog(page, filter, projectId).then((response) => {
    if (response) {
        response.items.map((timeLog) => {
            return timeLogs.push(timeLog);
        });

        result = result + response.count
        page = page + 1

        if (result !== response.total_result) {
        return appendNewTimeLogFromServer(timeLogs, result, page, filter, projectId);
        }
        return timeLogs;
    }
    });
};