import TimeGrid from '../TimeGrid'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-big-calendar'


export default function WeekView({
  date,
  localizer,
  max = localizer.endOf(new Date(), 'day'),
  min = localizer.startOf(new Date(), 'day'),
  scrollToTime = localizer.startOf(new Date(), 'day'),
  ...props
}) {

  const currRange = useMemo(
    () => WeekView.range(date, { localizer }),
    [date, localizer]
  )

  return (
    <>
      {currRange.length !== 0 &&
        currRange.map((item, index) => {
          let modified_range = [];
          modified_range.push(item);

          return <TimeGrid
            key={index}
            localizer={localizer}
            eventOffset={10}
            date={date}
            max={max}
            min={min}
            range={modified_range}
            scrollToTime={scrollToTime}
            {...props}
          />
        }
        )}
    </>
  )
}

WeekView.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
}

WeekView.defaultProps = TimeGrid.defaultProps

WeekView.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -2, 'week')

    case Navigate.NEXT:
      return localizer.add(date, 2, 'week')

    default:
      return date
  }
}

WeekView.range = (date, { localizer }) => {
  let firstOfWeek = localizer.startOfWeek()
  let start = localizer.startOf(date, 'week', firstOfWeek)
  const end = localizer.add(start, 13, 'day')

  let current = start
  const range = []

  while (localizer.lte(current, end, 'day')) {
    range.push(current)
    current = localizer.add(current, 1, 'day')
  }
  return range
}

WeekView.title = (date, { localizer }) => {
  let [start, ...rest] = WeekView.range(date, { localizer })
  return localizer.format({ start, end: rest.pop() }, 'dayRangeHeaderFormat')
}