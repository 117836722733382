import React, { useContext } from 'react'
import { SideMenuContext } from '../../providers/SideMenuProvider/SideMenuContext'
import './unAuthorized.scss'

const UnAuthorized = () => {
    const { expanded } = useContext(SideMenuContext)

    const withSideBar = {
        marginLeft: expanded ? "224px" : "48px",
    };

    return (
        <div className='unAuthorized-page' style={withSideBar}>
            <i className="fa fa-ban fa-5x text-danger pb-2" aria-hidden="true" />
            <div >
                <strong>
                    Unauthorized Access
                </strong>
            </div>
        </div>
    )
}

export default UnAuthorized