import React from "react";
import { FormCheck } from "react-bootstrap";
import "./primary-radio.scss";

const PrimaryRadio=({
  id,
  label,
  value,
  name,
  text,
  onChange,
  defaultChecked,
  isDisabled
}) => {

  return (
    <div className="primary-radio-container">
      <div className="primary-radio-holder">
        <FormCheck
          id={id}
          label={label}
          type='radio'          
          value={value}
          name={name}
          defaultChecked={defaultChecked}
          disabled={isDisabled}
          onChange={(event) => {
            onChange && onChange(event);
          }}
        />
      </div>

      <label>{text}</label>
    </div>
  );
};

export default PrimaryRadio;
